.myimages{
    height: 70px;
    width: 70px;
    margin: 5px;
    border-radius: 50%;
    object-fit: cover;
    float: left;
    position: relative;
    img{
       height: 100%;
       width: 100%;
       border-radius: 50%;
    } 
    i{
       display: none;
    }
   }
   .myimages:hover{
       background-color: #e93314;
       cursor: pointer;
       img{
         opacity: 0.3;
       }
       i{
         display: block;
         font-size: 40px;
         position: absolute;
         top:21px;
         left:22px;
         color: white;
       }
     }
     .preloaders {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent white */
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 9999;
    }
    
    .loaders {
      border: 8px solid #f3f3f3; /* Light grey */
      border-top: 8px solid rgb(62, 64, 149); /* Blue */
      border-radius: 50%;
      width: 50px;
      height: 50px;
      animation: spin 1s linear infinite;
    }
    
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }